const handlesRecoverPassword = {
    recover: async (email) => {
        $("#btnRecoverPassword").attr("disabled", "disabled").html(`<i class="fas fa-circle-notch fa-spin"></i>`);

        try {
            let data = { email, password };

            let responseAuth = await http.post(`login/recover-password`, data);

            if (!responseAuth.data.success) {
                return Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    html: responseAuth.data.message,
                });
            }

            return Swal.fire({
                icon: "success",
                title: "Tudo certo",
                html: responseAuth.data.message,
            }).then(() => {
                $("#formRecoverPassword").trigger("reset");
                $("#modalRecoverPassword").modal("hide");
            });
        } catch (error) {
            catchDefault(error);
        } finally {
            $("#btnRecoverPassword").removeAttr("disabled").html(`Recuperar senha`);
        }
    }
};

$("#formRecoverPassword").validate({
    rules: {
        modalRecoverPasswordEmail: { required: true, email: true },
    },
    messages: {
        modalRecoverPasswordEmail: { required: "Campo obrigatório", email: "E-mail inválido" },
    },
    errorClass: 'is-invalid',
    validClass: "is-valid",
    errorElement: "small",
    errorPlacement: function (error, element) {
        $(element)
            .closest("div")
            .find(".invalid-feedback")
            .append(error);
    },
    submitHandler: function () {
        handlesRecoverPassword.recover($("#modalRecoverPasswordEmail").val());
    }
});